<template>
    <div class="contact">
        <l-header></l-header>
        <img-box img="banner5.png"></img-box>
        <div class="contact-info">
            <div class="info-item">
                <img-box img="contact_icon1.png" width="108px" height="108px"></img-box>
                <p>400-007-2118</p>
            </div>
            <div class="info-item">
                <img-box img="contact_icon2.png" width="108px" height="108px"></img-box>
                <p>huoxche666</p>
            </div>
            <div class="info-item">
                <img-box img="contact_icon3.png" width="108px" height="108px"></img-box>
                <p>873768608@qq.com</p>
            </div>
            <div class="info-item">
                <img-box img="contact_icon4.png" width="108px" height="108px"></img-box>
                <p>湖北省武汉市汉阳区琴台大道北大资源首座17楼05室</p>
            </div>
        </div>
        <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:355px; margin-bottom: 50px" :scroll-wheel-zoom="true" @click="getClickInfo"></baidu-map>
        <l-footer></l-footer>
    </div>
</template>

<script>

import LHeader from "@/components/Header";
import ImgBox from "@/components/ImgBox";
import LFooter from "@/components/LFooter";

export default {
    name: "contactUs",
    components: {
        LHeader, ImgBox, LFooter
    },
    data(){
        return {
            center: {lng: 114.220883, lat: 30.575527},
            zoom: 13
        }
    },
    methods:{
        handler ({BMap, map}) {
            var point = new BMap.Point(114.220883, 30.575527)
            map.centerAndZoom(point, 15)
            var marker = new BMap.Marker(point) // 创建标注
            map.addOverlay(marker) // 将标注添加到地图中
            var circle = new BMap.Circle(point, 15, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            map.addOverlay(circle)
        },
        getClickInfo (e) {
            console.log(e.point.lng)
            console.log(e.point.lat)
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        }
    }
}
</script>

<style scoped lang="scss">
.contact{
    background: #F5F6F8;
}
.contact-info{
    display: flex;
    flex-direction: row;
    padding: 96px 0;
    justify-content: center;
    .info-item{
        margin-right: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-child{
            margin-right: 0;
        }
        p{
            padding-top: 36px;
            font-size: 20px;
            color: #333;
            max-width: 260px;
            text-align: center;
        }
    }
}
</style>